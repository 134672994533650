import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

import PageHead from "@Components/PageHead";
import "./slps.scss";

function SLPS(props: any) {
  const data = useStaticQuery(PageQuery);
  return (
    <>
      <PageHead
        backgroundImg={data.slpsBanner.childImageSharp.fluid}
        containerStyle={{
          background: "linear-gradient(30deg, #f7200280 40%, #ff737a 100%)",
        }}
      >
        <h2 className="head-title">
          <span style={{ color: "#fff" }}>SLPS</span>
        </h2>
      </PageHead>
      <div className="project container">
        <div className="store-listing">
          <a
            href="https://play.google.com/store/apps/details?id=com.fregmaa.slps"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Img
              fluid={data.playStore.childImageSharp.fluid}
              alt="Play Store Bagde"
              style={{ width: 200 }}
            />
          </a>
        </div>
        <div className="project__head">
          <h2 className="project__head-title">SLPS React Native Application</h2>
          <ul className="project__stack">
            <li className="project__stack--list">Typescript</li>
            <li className="project__stack--list">React Native</li>
            <li className="project__stack--list">React Apollo</li>
            <li className="project__stack--list">Apollo GraphQL</li>
            <li className="project__stack--list">Express.js</li>
            <li className="project__stack--list">Prisma</li>
          </ul>
        </div>
        <div className="project__background">
          <Img
            fluid={data.slpsWelcome.childImageSharp.fluid}
            alt="SLPS Welcome Screen"
            className="project__images"
            draggable={false}
          />
          <div className="project__motivation">
            <h2 className="project__subHeading">Background</h2>
            <div className="content-container">
              <p>
                We wanted to connect our community together where we can share
                information to all at a single place. Making the hassle of
                finding information completely gone.
              </p>
              <p>
                Having to know person contact can be really helpful when the
                need of.
              </p>
            </div>
          </div>
          <div className="project__solution">
            <h2 className="project__subHeading">Challenges</h2>
            <div className="content-container">
              <p>
                The solution was to create something that can help solve the
                problem most efficiently. And that's when we decided to create a
                mobile application for this as it seems perfect choice over a
                web app.
              </p>
              <p>
                Now since we are a web developer only feasible choice to create
                a mobile application was with <strong>React Native</strong>.
              </p>
              <p>
                Having knowledge of React on the web makes transitioning to
                React Native a bit simpler.
              </p>
              <p>
                Now for the backend, we wanted to make API that is easily
                scalable and manageable. REST seems the first choice for
                scalable API, but it's not quite manageable when app scales and
                having to deal with multiple endpoints is quite challenging.
                Finally, we decided to go with GraphQL.
              </p>
              <p>
                GraphQL is a new player in the API design world, one of the
                advantages of GraphQL is you can fetch exact data that you
                needed.
              </p>
              <p>
                Since GraphQL uses queries over Http request endpoint (REST),
                you don't have to deal with multiple endpoints, GraphQL uses
                single endpoint(POST) to send and retrieve data.
              </p>
            </div>
          </div>
          <div className="project__result">
            <h2 className="project__subHeading">Result</h2>
            <div className="content-container">
              <p>
                We launched SLPS application to Play Store and people already
                started using and liking it.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SLPS;

export const PageQuery = graphql`
  query {
    playStore: file(
      relativePath: { eq: "vendor_logos/google-play-badge.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    slpsBanner: file(relativePath: { eq: "projects/slps/SLPS.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    slpsWelcome: file(relativePath: { eq: "projects/slps/SLPS_welcome.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
